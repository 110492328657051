const load = () => {
	'use strict';

	!function(){let analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){let e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(let t=0;t<analytics.methods.length;t++){let e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){let n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";let a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
      // load Segment library
      analytics.load('9xOE30z6a6E9Y3gbqPYOtDez97ISDjM7');
    }}();
};

const track = (name, data) => {
	const analytics = window.analytics || [];
	analytics.track(name, data);
};

const identify = (name, data) => {
	const analytics = window.analytics || [];
	analytics.identify(name, data);
};

const dataLayer = (data) => {
	const dataLayer = window.dataLayer || [];
	dataLayer.push(data);
};

const isLoaded = () => !!window.analytics;

export default { load, track, identify, dataLayer, isLoaded };
